import React, { useState } from "react";
import {
    Typography,
    ListItem,
    ListItemText,
    Checkbox,
    Box,
    TextField,
    Button,
    Snackbar,
    Alert,
} from "@mui/material";
// import ThumbUpIcon from "@mui/icons-material/ThumbUp";
// import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import "../styles/index.css";

interface PredictionProps {
    items: icdResponse[];
    jsonklang: Record<string, any>;
    onCheckboxChange: (index: number) => void;
    onAcknowledgeChange: (strings: string[]) => void;
}

interface icdResponse {
    label: string;
    probability: number;
    description: string;
}

function Prediction({
    items,
    jsonklang,
    onCheckboxChange,
    onAcknowledgeChange,
}: PredictionProps) {
    const [checkedItems, setCheckedItems] = useState<boolean[]>(
        Array(items.length).fill(false)
    );
    const [newTag, setNewTag] = useState<string>(""); // State for the new tag input
    const [tags, setTags] = useState<string[]>([]); // State to hold the created tags
    const [consecutiveSpaces, setConsecutiveSpaces] = useState(0); // State to track consecutive spaces

    const [satisfyDisabled, setSatisfyDisabled] = useState(false);
    const [notSatisfyDisabled, setNotSatisfyDisabled] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCheckboxChange = (index: number) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
        onCheckboxChange(index); // Inform parent about checkbox change

        // If the checkbox is checked, add the selected item to tags
        if (newCheckedItems[index]) {
            const newItem = items[index];
            setTags([...tags, newItem.label]);
        } else {
            const newItem = items[index];
            const updatedTags = tags.filter((tag) => tag !== newItem.label);
            setTags(updatedTags);
        }
    };

    const handleNewTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewTag(event.target.value);
    };

    const handleNewTagKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === " ") {
            // If space key is pressed
            event.preventDefault(); // Prevent space from being entered into the input

            if (newTag.trim() !== "") {
                const newTags = [...tags];
                for (let i = 0; i <= consecutiveSpaces; i++) {
                    newTags.push(newTag.trim()); // Add tags based on consecutive spaces
                }
                setTags(newTags);
                setNewTag(""); // Clear the input field
            }

            setConsecutiveSpaces(consecutiveSpaces + 1); // Increment consecutive spaces
        } else {
            setConsecutiveSpaces(0); // Reset consecutive spaces counter when a character other than space is pressed
        }
    };

    const handleTagDelete = (index: number) => {
        const updatedTags = tags.filter((_, i) => i !== index);
        setTags(updatedTags);
    };

    const handleAcknowledge = async (
        acknowledgeType: "Satisfy" | "NotSatisfy" | "Acknowledge"
    ) => {
        if (acknowledgeType === "Satisfy") {
            if (notSatisfyDisabled === true) {
                setSatisfyDisabled(false);
                setNotSatisfyDisabled(false);
            } else {
                setSatisfyDisabled(false);
                setNotSatisfyDisabled(true);
            }
        } else if (acknowledgeType === "NotSatisfy") {
            if (satisfyDisabled === true) {
                setSatisfyDisabled(false);
                setNotSatisfyDisabled(false);
            } else {
                setSatisfyDisabled(true);
                setNotSatisfyDisabled(false);
            }
        } else if (acknowledgeType === "Acknowledge") {
            // Handle the acknowledge action without toggling button states
            const selectedItems = items.filter(
                (_, index) => checkedItems[index]
            );
            const requestBody = {
                Correct: selectedItems,
                Addition: tags,
                Satisfaction: selectedItems.length > 0 ? true : false
            };
            const updateDoc = {
                JsonKlang: jsonklang,
                Review: requestBody,
            };

            try {
                const response = await fetch(
                    // "http://127.0.0.1:5010/api/icd/feedback",
                    // "http://compute-model:5010/api/icd/feedback",
                    "https://dev-atlas-api-compute.optimizecare.com/api/icd/feedback",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(updateDoc),
                    }
                );
                setSnackbarOpen(true);
                if (response.ok) {
                    setCheckedItems(Array(items.length).fill(false));
                    setNewTag("");
                    setTags([]);
                    setConsecutiveSpaces(0);
                    onAcknowledgeChange([]);
                } else {
                    console.error("API error:", response.statusText);
                }
                setSatisfyDisabled(false);
                setNotSatisfyDisabled(false);
                setCheckedItems(Array(items.length).fill(false));
                setNewTag("");
                setTags([]);
                setConsecutiveSpaces(0);
            } catch (error) {
                console.error("Network error:", error);
            }
        }
    };
    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const textColor = (percentage: number): string => {
        if (percentage > 80) {
            return "#FFFFFF";
        } else {
            return "#000000";
        }
    };

    const getBackgroundColor = (percentage: number): string => {
        if (percentage < 10) {
            return "#FFFFFF";
        } else if (percentage < 20) {
            return "#ECF0F5";
        } else if (percentage < 30) {
            return "#DAE1EA";
        } else if (percentage < 40) {
            return "#C7D2E0";
        } else if (percentage < 50) {
            return "#B5C3D6";
        } else if (percentage < 60) {
            return "#A2B5CB";
        } else if (percentage < 70) {
            return "#90A6C1";
        } else if (percentage < 80) {
            return "#7D97B7";
        } else if (percentage < 90) {
            return "#6B88AC";
        } else {
            return "#5879A2";
        }
    };

    return (
        <div className="box">
            <div
                style={{
                    width: "100%",
                    justifyContent: "center",
                    textAlign: "center",
                }}>
                <Typography variant="h3">Prediction</Typography>
            </div>
            <Box className="darkerBox">
                <>
                    {items.map((item, index) => (
                        <>
                            <ListItem
                                key={index}
                                sx={{
                                    padding: "8px 0",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                <Box
                                    sx={{
                                        
                                        display: "flex",
                                        width: "100%",
                                        backgroundColor: getBackgroundColor(
                                            item.probability * 100
                                        ),
                                        borderRadius: "8px",
                                        alignItems: "center",
                                    }}>
                                    <ListItemText
                                        primary={`${item.label}`}
                                        primaryTypographyProps={{
                                            color: textColor(
                                                item.probability * 100
                                            ),
                                        }}
                                        secondary={`${item.description}`}
                                        secondaryTypographyProps={{
                                            color: textColor(
                                                item.probability * 100
                                            ),
                                        }}
                                        sx={{
                                            marginLeft: "16px",
                                        }}
                                    />
                                    <Checkbox
                                        checked={checkedItems[index]}
                                        onChange={() =>
                                            handleCheckboxChange(index)
                                        }
                                        sx={{
                                            color: textColor(
                                                item.probability * 100
                                            ),
                                        }}
                                    />
                                </Box>
                            </ListItem>
                        </>
                    ))}
                </>
            </Box>
            <Box sx={{ width: "100%" }}>
                {/* <Box sx={{ display: "flex", flexDirection: "row" , gap:"16px", }}>
                    <Button
                        className="darkButton"
                        variant="contained"
                        color="success"
                        startIcon={<ThumbUpIcon />}
                        onClick={() => handleAcknowledge("Satisfy")}
                        disabled={satisfyDisabled}>
                        Satisfy
                    </Button>
                    <Button
                        className="darkButton"
                        variant="contained"
                        color="error"
                        startIcon={<ThumbDownIcon />}
                        onClick={() => handleAcknowledge("NotSatisfy")}
                        disabled={notSatisfyDisabled}>
                        Not Satisfy
                    </Button>
                </Box> */}
                <TextField
                    label="Addition"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={newTag}
                    size="small"
                    onChange={handleNewTagChange}
                    onKeyDown={handleNewTagKeyDown}
                />
                <div className="tags-container">
                    <div className="tags">
                        {tags.map((tag, index) => (
                            <span key={index} className="tag">
                                {tag}
                                <span
                                    className="tag-delete"
                                    onClick={() => handleTagDelete(index)}>
                                    &#215;
                                </span>
                            </span>
                        ))}
                    </div>
                </div>
            </Box>
            <Button
                className="darkButton"
                variant="contained"
                size="large"
                style={{ background:"#043673"}}
                onClick={() => handleAcknowledge("Acknowledge")}>
                Acknowledge
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={closeSnackbar} severity="success">
                    Save data successful!
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Prediction;
