import { Typography, TextField, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import "../styles/index.css";

interface DataInputProps {
    fieldLabels: string[];
    formData: Record<string, string>;
    onFormDataChange: (field: string, value: string) => void; // Add this
    onSubmit: (response: responseSchema[]) => void;
    onFormReset: () => void;
}

interface responseSchema {
    Info: MyFormData[];
    Detail: MyFormData[];
}

interface MyFormData {
    Name: string;
    Value: string;
}

interface PatientData {
    Name: string;
    Value: string;
}

const PatientLabels: string[] = [
    "Weight (Kg)",
    "Height (cm)",
    "Blood Pressure (mmHg)",
    "Pulse Rate (bpm)",
];

const DataInput: React.FC<DataInputProps> = ({ formData, fieldLabels, onFormDataChange, onSubmit, onFormReset }) => {
    const handleInputChange = (field: string, value: string) => {
        onFormDataChange(field, value);
    };

    const handleFormSubmit = () => {
        const PatientItem: PatientData[] = PatientLabels.map((label) => ({
            Name: label,
            Value: formData[label] || "",
        }));
        const formDataArray: MyFormData[] = fieldLabels.map((label) => ({
            Name: label,
            Value: formData[label] || "",
        }));
        const response = [
            {
                Info: PatientItem,
                Detail: formDataArray,
            },
        ];
        onSubmit(response);
    };

    return (
        <div
            className="box"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                <Typography variant="h3" style={{ textAlign: "center" }}>
                    Clinical Summary
                </Typography>
            </div>
            <div style={{width:"100%"}}>
                {fieldLabels.map((label, index) => (
                    <TextField
                        multiline
                        key={index}
                        label={label}
                        variant="outlined"
                        rows={10}
                        fullWidth
                        size="small"
                        margin="dense"
                        value={formData[label] || ""}
                        onChange={(e) =>
                            handleInputChange(label, e.target.value)
                        }
                    />
                ))}
            </div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: "10px",
                }}>
                <Button
                    className="darkButton"
                    variant="contained"
                    style={{ flex:"60%",background:"#043673" }}
                    endIcon={<SendIcon />}
                    onClick={handleFormSubmit}>
                    Submit
                </Button>
                <Button
                    className="darkButton"
                    style={{ background:"#043673" }}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                        onFormReset(); 
                    }}>
                    DEL
                </Button>
            </div>
        </div>
    );
}

export default DataInput;
