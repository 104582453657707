import React from "react";
import { Typography } from "@mui/material";

interface HeaderProps {
    title: string;
}

function Header({ title }: HeaderProps) {
    return (
        <div className="header">
            <Typography variant="h2">{title}</Typography>
        </div>
    );
}

export default Header;
