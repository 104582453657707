import { createRoot } from "react-dom/client";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import App from "./App";

const theme = createTheme({
    typography: {
        fontFamily: "IBM Plex Sans Thai, sans-serif",
        h2: {
            fontSize: "32px",
            fontWeight: "600",
            color: "var(--basic-ui-colors-black-1, #1C1C1E)",
        },
        h3: {
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "150%",
        },
        h4: {
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
        },
    },
});

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
);

// ReactDOM.render(
//     <ThemeProvider theme={theme}>
//         <App />
//     </ThemeProvider>,
//     document.getElementById("root")
// );
