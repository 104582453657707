import { useState, ChangeEvent } from "react";
import { Grid, Box, Typography, TextField, Button, Snackbar,Alert } from "@mui/material";
import "./styles/index.css";
import Header from "./components/Header";
import DataInput from "./components/DataInput";
import Prediction from "./components/Prediction";

interface MyFormData {
    Info: PatientData[];
    Detail: PatientData[];
}

interface PatientData {
    Name: string;
    Value: string;
}

interface icdResponse {
    label: string;
    probability: number;
    description: string;
}

// eslint-disable-next-line
const mockPredictionItems: string[] = [
    "Prediction 1",
    "Prediction 2",
    "Prediction 3",
    "Prediction 4",
    "Prediction 5",
    "Prediction 6",
    "Prediction 7",
    "Prediction 8",
    "Prediction 9",
    "Prediction 10",
    // ... add more mock data items
];
const fieldLabels: string[] = [
    // "Chief Complaint",
    // "Present Illness",
    // "Physical Examination",
    // "Previous Treatment",
    // "Underlying Disease",
    // "Provisional Diagnosis",
    // "Reason Of Admission",
    // "Treatment",
    "Clinical Summary"
];

function App() {
    const master_password = process.env.REACT_APP_PASSWORD;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };


    const [password, setPassword] = useState<string>("");
    const [showContent, setShowContent] = useState<boolean>(false);

    const [predictionItems, setPredictionItems] = useState<icdResponse[]>([]);
    const [jsonklangData, setJsonklangData] = useState({});
    const [formData, setFormData] = useState<Record<string, string>>({});

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<boolean[]>(
        Array(fieldLabels.length).fill(false)
    );

    const handleCheckboxChange = (index: number) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
    };

    const handleAcknowledgeSubmit = () => {
        setPredictionItems([]);
        setFormData({});
    };

    const handleFormDataChange = (field: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSubmit = async (formDataArray: MyFormData[]) => {
        try {
            const filteredInfo = formDataArray[0].Info.filter(
                (data) => data.Value !== ""
            );
            const filteredDetail = formDataArray[0].Detail.filter(
                (data) => data.Value !== ""
            );

            const requestBody = {
                Patient: {
                    LineItem: [
                        {
                            GroupName: "visit_information_list",
                            Code: "",
                            Item: filteredInfo.map((data) => ({
                                Code: "",
                                Name: "",
                                Nameen: data.Name,
                                Value: [data.Value],
                                Unit: "",
                            })),
                        },
                    ],
                    LineItemUnstructure: {
                        GroupName: "order_information_list",
                        Code: "",
                        Item: filteredDetail.map((data) => ({
                            Amount: "",
                            Code: "Provisional Diagnosis",
                            Name: "Provisional Diagnosis",
                            Number: "",
                            Usage: "",
                            Value: [data.Value],
                        })),
                    },
                },
            };

            setJsonklangData(requestBody);
            const response = await fetch(
                // "http://127.0.0.1:5010/api/icd/predict",
                // "http://compute-model:5010/api/icd/predict",
                "https://dev-atlas-api-compute.optimizecare.com/api/icd/predict",
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (response.ok) {
                const predictionData: icdResponse[] = await response.json();
                setPredictionItems(predictionData);
            } else {
                console.error("API error:", response.statusText);
            }
        } catch (error) {
            console.error("Network error:", error);
        }
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmitPassword = () => {
        if (password === master_password) {
            setShowContent(true);
        } else {
            setSnackbarOpen(true);
        }
    };

    const handleTextFieldKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === "Enter") {
            handleSubmitPassword();
        }
    };

    return (
        <>
            {!showContent && (
                <Grid
                    container
                    spacing={3}
                    style={{ alignItems: "center", height: "100vh" }}>
                    <Grid
                        item
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                        }}>
                        <Box className="box">
                            <Typography variant="h2">
                                Please Enter Password
                            </Typography>
                            <TextField
                                label="Password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                onKeyDown={handleTextFieldKeyDown}
                                size="small"></TextField>
                            <Button
                                className="darkButton"
                                variant="contained"
                                onClick={handleSubmitPassword}
                                style={{ flex: "80%", background:"#043673" }}>
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
            {showContent && (
                <Grid container spacing={3} style={{ height: "100vh" }}>
                    <Grid item xs={12} style={{ height: "100px" }}>
                        <Header title="ICD 10 Prediction" />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={3}
                        style={{
                            margin: "-12px 24px 24px 0",
                            alignItems:"center",
                            justifyContent: "center"
                        }}>
                        <Grid
                            item
                            spacing={3}
                            xs={12}
                            lg={6}>
                            <DataInput
                                fieldLabels={fieldLabels}
                                formData={formData}
                                onFormDataChange={handleFormDataChange}
                                onSubmit={handleSubmit}
                                onFormReset={handleAcknowledgeSubmit}
                            />
                        </Grid>
                        {predictionItems.length !== 0 && (
                            <Grid item spacing={3} xs={12} lg={6}>
                                <Prediction
                                    items={predictionItems}
                                    jsonklang={jsonklangData}
                                    onCheckboxChange={handleCheckboxChange}
                                    onAcknowledgeChange={
                                        handleAcknowledgeSubmit
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={closeSnackbar} severity="error">
                    Wrong Password
                </Alert>
            </Snackbar>
        </>
    );
}

export default App;
